var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-tabbar',{attrs:{"active-color":"#000000","inactive-color":"#4D5164"},on:{"change":_vm.change},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"vip-account"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('div',{staticClass:"icon"},[_c('van-image',{attrs:{"width":"100%","height":"auto","src":props.active
              ? require(`./img/tab1-active.png`)
              : require(`./img/tab1.png`),"fit":"contain"}})],1)]}}])},[_vm._v(" Account ")]),_c('van-tabbar-item',{attrs:{"name":"vip-spread"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('div',{staticClass:"icon"},[_c('van-image',{attrs:{"width":"100%","height":"auto","src":props.active
              ? require(`./img/tab2-active.png`)
              : require(`./img/tab2.png`),"fit":"contain"}})],1)]}}])},[_vm._v(" Spread ")]),_c('van-tabbar-item',{attrs:{"name":"vip-square"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('div',{staticClass:"icon"},[_c('van-image',{attrs:{"width":"100%","height":"auto","src":props.active
              ? require(`./img/tab3-active.png`)
              : require(`./img/tab3.png`),"fit":"contain"}})],1)]}}])},[_vm._v(" Square ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
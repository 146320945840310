<template>
  <div class="account">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
          <span class="text">Muzi Xiansen</span>
          <span class="vip">SVIP</span>
        </template>
        <template #right>
          <div class="detail" @click="to_vip_information">Detail</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="top div-bg" div-bg>
      <van-image width="100%" :src="require('./img/img1.png')" />
      <div class="bg">
        <div class="bg-1" @click="open_share(1)"></div>
        <div class="bg-2" @click="open_share(2)"></div>
        <div class="bg-3" @click="open_share(3)"></div>
      </div>
    </div>

    <div class="content div-bg">
      <van-image width="100%" :src="require('./img/img2.png')" />
      <div class="bg">
        <div class="add" @click="show = true"></div>
      </div>
    </div>

    <div class="modal-box">
      <van-dialog v-model="show1" :showConfirmButton="false" width="auto">
        <div class="dialog-box1 div-bg" v-if="shareType === 1">
          <van-image width="100%" :src="require('./img/share1.png')" />
          <div class="bg">
            <div class="close" @click="show1 = false"></div>
          </div>
        </div>

        <div class="dialog-box2 div-bg" v-if="shareType === 2">
          <van-image width="100%" :src="require('./img/share2.png')" />

          <div class="bg">
            <div class="close" @click="show1 = false"></div>
          </div>
        </div>

        <div class="dialog-box3 div-bg" v-if="shareType === 3">
          <van-image width="100%" :src="require('./img/share3.png')" />
          <div class="bg">
            <div class="close" @click="show1 = false"></div>
          </div>
        </div>
      </van-dialog>

      <van-action-sheet v-model="show">
        <div class="buy-content div-bg">
          <van-image width="100%" :src="require(`./img/add.png`)" />
          <div class="close" @click.stop="show1 = false"></div>
          <div class="bg" @click="show = false"></div>
        </div>
      </van-action-sheet>
    </div>

    <VipTabbar name="vip-spread" />
  </div>
</template>

<script>
import VipTabbar from "@/components/vip-tabbar";
export default {
  components: { VipTabbar },
  data() {
    return {
      show: false,
      show1: false,
      shareType: 1,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: "/my" });
    },
    open_share(type) {
      this.show1 = true;
      this.shareType = type;
    },
    to_vip_information() {
      this.$router.push({ path: "/vip/information" });
    },
    to_vip_rewards() {
      this.$router.push({ path: "/vip/rewards" });
    },
    to_vip_commission() {
      this.$router.push({ path: "/vip/commission" });
    },
    to_vip_history() {
      this.$router.push({ path: "/vip/history" });
    },
    to_vip_rules() {
      this.$router.push({ path: "/vip/rules" });
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__title {
      overflow: initial;
    }

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .text {
      font-weight: 600;
      font-size: 2.25rem;
      color: #000000;
      margin-right: 1rem;
    }

    .vip {
      border-radius: 3.125rem;
      padding: 0.125rem 1rem;
      font-size: 1.375rem;
      color: #ffffff;
      line-height: 1.875rem;
      background-color: #e5285b;
    }

    .detail {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      font-size: 1.5rem;
      color: #3362ff;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      border: 1px solid #3362ff;
      line-height: 1;
    }
  }

  .top {
    .bg {
      display: flex;
      div {
        width: calc(100% / 3);
        height: 100%;
        opacity: 0.5;
      }
    }
  }

  .content {
    padding: 0 1.5rem 7.5rem;
    margin-top: 1.25rem;

    .bg {
      .add {
        height: 6.25rem;
      }
    }
  }

  .modal-box {
    .van-dialog {
      background-color: transparent;
    }

    .dialog-box1 {
      width: 28.625rem;

      .close {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 3.3rem;
        right: 0;
      }
    }

    .dialog-box2 {
      width: 38.25rem;

      .close {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .dialog-box3 {
      width: 38.25rem;

      .close {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .buy-content {
      padding-bottom: 6.25rem;
    }
  }

  .btn {
    margin-top: 1.25rem;
    position: sticky;
    bottom: 0;
    z-index: 999;
    line-height: 0;
  }
}
</style>
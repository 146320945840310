<script>
export default {
  props: { name: String },
  data() {
    return {
      active: "home",
    };
  },
  mounted() {
    this.active = this.name;
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    change(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>

<template>
  <van-tabbar
    v-model="active"
    active-color="#000000"
    inactive-color="#4D5164"
    @change="change"
  >
    <van-tabbar-item name="vip-account">
      Account
      <template #icon="props">
        <div class="icon">
          <van-image
            width="100%"
            height="auto"
            :src="
              props.active
                ? require(`./img/tab1-active.png`)
                : require(`./img/tab1.png`)
            "
            fit="contain"
          />
        </div>
      </template>
    </van-tabbar-item>

    <van-tabbar-item name="vip-spread">
      Spread
      <template #icon="props">
        <div class="icon">
          <van-image
            width="100%"
            height="auto"
            :src="
              props.active
                ? require(`./img/tab2-active.png`)
                : require(`./img/tab2.png`)
            "
            fit="contain"
          />
        </div> </template
    ></van-tabbar-item>

    <van-tabbar-item name="vip-square">
      Square
      <template #icon="props">
        <div class="icon">
          <van-image
            width="100%"
            height="auto"
            :src="
              props.active
                ? require(`./img/tab3-active.png`)
                : require(`./img/tab3.png`)
            "
            fit="contain"
          />
        </div> </template
    ></van-tabbar-item>
  </van-tabbar>
</template>

<style lang='scss' scoped>
.van-tabbar {
  z-index: 9999;
}
.icon {
  width: 2.5rem;
  height: 2.5rem;
}
.van-tabbar-item--active {
  font-weight: bold;
}
</style>
